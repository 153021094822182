import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'

const AgentSection = ({ agents }) => {
  const { pageInfo, totalCount, nodes } = agents
  return (
    <div>
      <div className='mb-2 p-3 mb-2 bg-secondary'>
        <h6 className=' text-white mb-0'>
          Results {pageInfo.currentPage} - {pageInfo.pageCount} of {totalCount}{' '}
          Companies
        </h6>
      </div>
      <div>
        {nodes.map(item => (
          <div key={item.id} className='card mb-4 p-2'>
            <Link to={`/real-estate-companies/${item.slug}`}>
              <div className='border-b d-flex align-items-center my-2'>
                <div
                  className='thumb me-4'
                  style={{ width: '100px', height: '100px' }}
                >
                  <Img image={getImage(item.logo)} alt='card image' />
                </div>
                <div className='ms-2 px-2'>
                  <h2 className='mb-2 h6'>{item.name}</h2>

                  <div className='d-flex align-items-center'>
                    <p className='me-2'>Address:</p>
                    <span
                      className='text-muted'
                      dangerouslySetInnerHTML={{
                        __html: item.address.childMarkdownRemark.html
                      }}
                    />
                    
                  </div>
                  <p className='text-blue'>Click to view Listings</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AgentSection
