import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/LayoutView'
import AgentsPage from '../components/Agents'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import Breadcrumb from "../components/PropertyDetails/breadcrumb";


const Agents = ({ data, pageContext }) => {
  const { agents } = data
  const title = 'Real Estate Companies and Developers in Lagos, Abuja, Nigeria'
  const description =  'List of the popular real estate companies, developers and agents in Lagos, Abuja Nigeria with verified properties. '
  const items = [
    { to: '/', label: 'Home' },
    {label: title }
  ]
  
  return (
    <Layout>
      <GatsbySeo
      title={title}
      description= {description}
      openGraph={{
        title: {title},
        description: {description},
        url: 'https://images.ctfassets.net/abyiu1tn7a0f/131ePdA3lUnISWi6UzYfq9/ef10c36ff7cef5ec33baa54fc809c167/apartments-and-houses-for-sale-ikate.jpg?h=250',
        images: 
          {
            url: 'https://images.ctfassets.net/abyiu1tn7a0f/131ePdA3lUnISWi6UzYfq9/ef10c36ff7cef5ec33baa54fc809c167/apartments-and-houses-for-sale-ikate.jpg?h=250',
            width: 850,
            height: 650,
            alt: 'Ownahome.ng picture',
          },
          
        site_name: 'Owning a home does not need to make you lose sleep',
      }}
      twitter={{
        handle: '@ownahome_ng',
        site: '@ownahome.ng',
        cardType: 'summary_large_image',
      }}
    />

<BreadcrumbJsonLd
      itemListElements={[
        {
          position: 1,
          name: 'Home',
          item: '/',
        },
        {
          position: 2,
          name: {title},
          item: '/real-estate-companies',
        },
      ]}
    />

      
              

      <div className='pt-5  mt-5 pd-bottom-70'>
        <h1 className='text-center h4'>Real Estate Companies and Developers in Lagos and Abuja</h1>
     <div  className='container '> <Breadcrumb >
              {items.map(({ to, label }) => (
                <Link key={to} to={to}>
                  {label}
                </Link>
              ))}
            </Breadcrumb>
            </div>
        <AgentsPage agents={agents} pageContext={pageContext} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AllAgentsTemplate($limit: Int!, $skip: Int!) {
    agents: allContentfulPropertyAgent(limit: $limit, skip: $skip) {
      nodes {
        ...PropertyAgent
      }
      pageInfo {
        pageCount
        hasPreviousPage
        hasNextPage
        currentPage
      }
      totalCount
    }
  }
`

export default Agents
