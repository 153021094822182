import React from 'react'
import { navigate } from '@reach/router'
import Pagination from 'react-paginate'
import Filters from '../common/Filters'
import AgentSection from './AgentSection'

const Agents = ({ agents }) => {
  const handlePageClick = data => {
    let selected = data.selected
    if (selected == 0) {
      return navigate(`/real-estate-companies`)
    } else {
      return navigate(`/real-estate-companies/page/${selected + 1}`)
    }
  }

  return (
    <div className='search-container'>
      <div className='row container-fluid  mx-auto'>
        <div className='col-xl-3 col-md-6 col-lg-4 sitebar'>
          <Filters />
        </div>
        <div className='col col-lg-6 col-md-6'>
          <AgentSection agents={agents} />
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ paddingTop: '30px' }}
          >
            <Pagination
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={agents.pageInfo.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
              initialPage={agents.pageInfo.currentPage - 1}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Agents
